import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseModal from '@/components/base/baseModal';
import PageEnum from '@/enums/pageEnum';
import VirtualCard from '@/pages/funds/withdraw/components/VirtualCard';
import { OperatingEnum, TypeEnum } from '@/pages/mine/bank/useStateHooks';
import { Button, Divider, ScrollShadow, useDisclosure } from '@nextui-org/react';
import { history } from '@umijs/max';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 虚拟钱包选择组件
 *
 * @param {IProps} props 组件接收的属性
 * @param {any[]} virtualList 列表
 * @param {ReactNode} children 子组件，点击会触发银行列表的显示
 * @param {any} setSelectVirtual 设置当前选择的银行卡的函数
 * @param {boolean} loading 加载状态，控制加载动画的显示
 * @returns 渲染的JSX元素
 */
export default ({
                  virtualList,
                  children,
                  setSelectVirtual,
                  loading,
                }: IProps) => {
  // 使用Disclosure hook管理抽屉的开闭状态
  const { isOpen, onOpen, onClose } = useDisclosure();
  // 使用国际化hook获取翻译函数
  const { t } = useTranslation();
  return (
    <>
      <>
        {/*点击子组件打开抽屉*/}
        <div onClick={onOpen}>{children}</div>
        <BaseModal isOpen={isOpen} onClose={onClose} title={t('选择转账地址')}>
          <ScrollShadow hideScrollBar className="max-h-[50vh] sm:h-[40vh]">
            <BaseLoadingDisplay loading={loading} list={virtualList} />
            <div className="px-4 sm:w-[500px]">
              {Array.isArray(virtualList) &&
                virtualList?.length !== 0 &&
                virtualList?.map((virtualInfo: any) => (
                  <div
                    key={virtualInfo.id}
                    onClick={() => {
                      setSelectVirtual(virtualInfo);
                      onClose();
                    }}
                  >
                    <VirtualCard data={virtualInfo} />
                    <Divider className="my-2 !bg-backgroundAuxiliaryColor" />
                  </div>
                ))}
            </div>
          </ScrollShadow>
          <div className="px-4 pt-4 sm:relative w-full  pb-[30px] bg-background">
            <Button
              className="mainColorButton"
              onClick={() => {
                // 点击按钮时，跳转到添加收款方式页面
                history.push(
                  `${PageEnum.ADD_COLLECTION}?type=${TypeEnum.VIRTUAL_CURRENCY}&operatingType=${OperatingEnum.ADD}&targetUrl=${PageEnum.WITHDRAW}`,
                );
              }}
            >
              {t('添加收款方式')}
            </Button>
          </div>
        </BaseModal>
      </>
    </>
  );
};

// 组件属性接口
interface IProps {
  virtualList: any[];
  children: ReactNode;
  setSelectVirtual: any;
  loading: boolean;
}

type IProps = {
  data: any;
};

/**
 * 虚拟卡信息卡片
 */
export default (props: IProps) => {
  const { data } = props;
  return (
    <>
      <div className="w-full rounded-md mt-8 px-4 py-4"  style={{wordWrap: 'break-word'}}>
        <div className="flex items-center mb-4">
          {/*<span className="w-[6px] h-[14px] bg-primary rounded-md mr-2"></span>*/}
          <div className="text-backContrastColor w-full flex items-center">
            <img src={data?.networkIcon} className='w-6 h-6 rounded-full mr-1' alt="" />
            {data?.networkName}
          </div>
        </div>
        <div className="mt-1 text-auxiliaryTextColor font-bold">
          {data?.addressUrl}
        </div>

      </div>
    </>
  );
};

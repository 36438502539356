import { IonList } from '@/assets/icons/funds/IonList';
import BaseTopNav from '@/components/base/baseTopNav';
import { CompletedOrderOrderProcessEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import WithdrawForm from '@/pages/funds/withdraw/components/WithdrawForm';
import useStateHooks from '@/pages/funds/withdraw/useStateHooks';
import { useTranslation } from 'react-i18next';
import { history } from 'umi';
import BaseTabs from '@/components/base/baseTabs';

/**
 * 提现页面
 * */
export default () => {
  const props = useStateHooks();
  const { t } = useTranslation();
  return (
    <div>
      <div className="pb-[16px]">
        <BaseTopNav
          title={t('提现')}
          rightNode={
            <IonList
              onClick={() => {
                history.push(
                  PageEnum.ORDER_HISTORY +
                  '?type=' +
                  CompletedOrderOrderProcessEnum.SUB,
                );
              }}
              className="text-backContrastColor  cursor-pointer p-4 box-content"
              width="18px"
              height="18px"
            />
          }
        />
      </div>
      {/*可提现余额*/}
      <BaseTabs
        value={props.currentTab}
        options={props.tabsList}
        onChange={props.setCurrentTab}
        classNames={{
          active: ' text-backContrastColor font-bold text-[15px]',
        }}
        tabsPropsProps={{
          classNames: {
            base: 'w-full',
            cursor: 'font-bold  !w-[20px] !h-[3px] rounded bg-primary',
            tabList: 'pl-4 pb-0',
          },
        }}
      />
      {/*提现信息表单*/}
      {<WithdrawForm {...props} availableBalance={props.availableBalance} />}
    </div>
  );
};

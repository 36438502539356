import PageEnum from '@/enums/pageEnum';
import { VerifiedStatesEnum } from '@/enums/businessEnum';
import {
  getAvailableBalanceApi,
  getExchangeRateApi,
  getPersonBankListApi,
  getVirtualWalletListApi,
  getWithdrawalChannelApi,
  withdrawApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@@/exports';
import { history } from '@umijs/max';
import { useMount, useUpdateEffect } from 'ahooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

/**
 * 使用状态钩子管理组件的状态和请求数据。
 *
 * 该函数主要负责初始化和管理组件内部的各种状态，以及通过调用API来获取数据。
 * 它返回一个包含各种状态和操作的对象，供组件使用。
 *
 * @return {Object} 返回一个包含以下属性的对象：
 *  - withdrawalAmountList: 提现金额列表
 *  - submitForm: 提交表单的状态和变更函数
 *  - onChangeSubmitForm: 修改提交表单信息的函数
 *  - rate: 费率信息
 *  - bankList: 银行卡列表
 *  - currentBank: 当前选择的银行卡
 *  - setCurrentBank: 设置当前选择的银行卡的函数
 *  - currentQuantity: 当前数量
 *  - setCurrentQuantity: 设置当前数量的函数
 *  - loading: 加载状态
 *  - availableBalance: 可用余额
 *  - withdraw: 提现操作的函数
 *  - currency: 当前选择的法币
 *  - currencyList: 法币列表
 *  - changeCurrency: 更改法币的函数
 */
export enum PayoutsMethodEnum {
  // 虚拟币提现
  WITHDRAWAL_OF_VIRTUAL_CURRENCY = '2',
  // 银行卡提现
  CARD_CASH_ADVANCES = '1',
}

export default function useStateHooks() {
  // 使用i18n钩子获取国际化函数
  const { t } = useTranslation();
  // 选择的通道
  const [channel, setChannel] = useState<any>();
  // 管理法币选择和获取可用余额
  const [currency, changeCurrency] = useState<any>();

  const { user } = useModel('user');

  const [currentQuantity, setCurrentQuantity] = useState<number>();
  const [isFirst, setIsFirst] = useState(true);
  // 初始化骨架屏幕的加载状态
  const [skeletonScreenLoading, setSkeletonScreenLoading] = useState(true);
  // 初始化提交表单的状态和变更函数
  const [submitForm, setSubmitForm] = useState({
    rechargeAmount: '',
    transactionPassword: '',
  });
  const onChangeSubmitForm = (value: any, type: keyof typeof submitForm) => {
    setSubmitForm({
      ...submitForm,
      [type]: value,
    });
  };
  // 顶部数据
  const tabsList = [
    {
      text: t('虚拟币提现'),
      value: PayoutsMethodEnum.WITHDRAWAL_OF_VIRTUAL_CURRENCY,
    },
    // {
    //   text: t('银行卡提现'),
    //   value: PayoutsMethodEnum.CARD_CASH_ADVANCES,
    // },
  ];
  const [currentTab, setCurrentTab] = useState(
    PayoutsMethodEnum.WITHDRAWAL_OF_VIRTUAL_CURRENCY,
  );
  // 使用自定义钩子获取可用余额，并在组件挂载时请求数据
  const {
    data: availableBalance,
    run: getAvailableBalanceReq,
    loading: availableBalanceLoading,
  } = useReq(getAvailableBalanceApi, {
    manual: true,
    loadingDefault: true,
  });
  // 在法币变化时更新可用余额请求
  useUpdateEffect(() => {
    if (currency && channel) {
      getAvailableBalanceReq({
        walletType: channel?.walletType,
        coinId: (currency as any)?.id,
        channelId: channel?.id,
      });
    }
  }, [currency, channel]);
  // 获取手续费和校验是否需要实名认证
  useMount(() => {
    if (user?.realnameStatus !== VerifiedStatesEnum.REAL) {
      toast.error(t('请先完成实名认证'));
      history.replace(PageEnum.VERIFIED);
      return;
    }
  });
  // 管理当前选择的银行卡
  const [currentBank, setCurrentBank] = useState();

  // 提现loading

  // 请求当前用户下的所有可用银行卡列表，并在组件挂载时触发请求
  const {
    data: bankList = [] as any,
    loading,
    run: getBankList,
  } = useReq(getPersonBankListApi, {
    manual: true,
    defaultValue: [],
    onSuccess(data: any) {
      if (data.length > 0) {
        setCurrentBank(data[0]);
      }
    },
  });

  const {
    data: channelList = [] as any,
    loading: channelLoading,
    run: getChannelApi,
  } = useReq(getWithdrawalChannelApi, {
    manual: true,
    defaultValue: [],
    onSuccess: (data: any) => {
      if (data.length > 0) {
        setChannel(data[0]);
        onChangeSubmitForm(
          RenderUtil.formatNum(data[0]?.minAmount, 2),
          'rechargeAmount',
        );
      } else {
        setChannel('');
        // setSkeletonScreenLoading(false)
        onChangeSubmitForm(RenderUtil.formatNum(0, 2), 'rechargeAmount');
      }
      setSkeletonScreenLoading(false)

    },
  });

  // 虚拟钱包
  const [selectVirtual, setSelectVirtual] = useState<any>();
  const {
    data: virtualList = [] as any,
    loading: virtualLoading,
    run: getVirtualList,
  } = useReq(getVirtualWalletListApi, {
    manual: true,
    defaultValue: [],
    onSuccess(data: any) {
      if (data.length > 0) {
        setSelectVirtual(data[0]);
      }else{
        // setIsFirst(false);
        // setSkeletonScreenLoading(false);
      }
      setSkeletonScreenLoading(false);

    },
  });

  const { refreshWallet } = useModel('user');
  // 提现操作的实现，包括各种验证和调用API
  const { run: withdrawReq, loading: withdrawLoading } = useReq(withdrawApi, {
    manual: true,
    defaultValue: [],
    loadingDefault: false,
    onSuccess(data) {
      toast.success(t('提现申请成功'));
      refreshWallet();
      history.push(`${PageEnum.WITHDRAW_SUCCESS}?id=${data}`);
    },
  });
  const { data: exchangeRate = [] as any, run: getExchangeRateReq } = useReq(
    getExchangeRateApi,
    {
      manual: true,
    },
  );
  useMount(() => {
    getExchangeRateReq();
  });
  // 切换提现方式(0是按金额1是按数据)
  const [withdrawalMethod, setWithdrawalMethod] = useState(0);
  // 根据当前选中的币种在汇率列表中查找
  const currentRate = useMemo(() => {
    return exchangeRate?.find?.((item: any) => item.name === currency?.name);
  }, [exchangeRate, currency]);
  // 根据当前选中的币种在汇率列表中查找
  const baseCurrency = useMemo(() => {
    return exchangeRate?.find?.((item: any) => item.name === 'USDT');
  }, [exchangeRate]);

  // 计算最终提现金额
  const finalAmount = useMemo(() => {
    let rechargeAmount = submitForm.rechargeAmount;
    if (
      currentTab === PayoutsMethodEnum.WITHDRAWAL_OF_VIRTUAL_CURRENCY &&
      currentRate?.rate !== null &&
      withdrawalMethod === 1
    ) {
      rechargeAmount = RenderUtil.toFixed(
        Number(submitForm.rechargeAmount) * currentRate?.rate,
        currentRate?.transactionAccuracy,
      );
    }
    return rechargeAmount;
  }, [submitForm.rechargeAmount, currentTab, currentRate, withdrawalMethod]);
  // 是否按汇率提现
  const isRateWithdraw = useMemo(() => {
    return (
      currentRate?.rate !== null &&
      currentTab === PayoutsMethodEnum.WITHDRAWAL_OF_VIRTUAL_CURRENCY
    );
  }, [currentTab, currentRate]);
  const withdraw = async () => {
    // 验证必要条件
    if (!currency) {
      return toast.error(t('请选择币种'));
    }
    if (channel?.type === 1 && !currentBank) {
      return toast.error(t('请选择银行卡'));
    }
    if (channel?.type === 2 && !selectVirtual) {
      return toast.error(t('请选择虚拟钱包'));
    }
    if (!submitForm.rechargeAmount || +submitForm.rechargeAmount === 0) {
      return toast.error(t('金额不能为0'));
    }

    if (!submitForm.transactionPassword) {
      return toast.error(t('请输入密码'));
    }
    // 提交数据
    const _to_add_data = {
      walletType: channel?.walletType,
      bankCardId: (currentBank as any)?.id,
      coinId: currency?.id,
      amount: finalAmount,
      payPwd: submitForm.transactionPassword,
      channelId: channel?.id,
      virtualWalletId: selectVirtual?.id,
    };
    // 调用API提交提现请求
    withdrawReq(_to_add_data);
  };
  useMount(() => {
    getBankList();
    getVirtualList();
  });
  useUpdateEffect(() => {
    getChannelApi({
      type: currentTab,
      virtualNetworkId:
        currentTab === PayoutsMethodEnum.WITHDRAWAL_OF_VIRTUAL_CURRENCY
          ? selectVirtual?.networkId
          : undefined,
    });
  }, [selectVirtual, currentTab]);


  useUpdateEffect(() => {
    if (
      !(loading || virtualLoading || channelLoading || availableBalanceLoading)
    ) {
      if (isFirst) {
        setIsFirst(false);
        setSkeletonScreenLoading(false);
      }
    }
  }, [
    loading,
    virtualLoading,
    channelLoading,
    availableBalanceLoading,
    isFirst,
  ]);
  useUpdateEffect(() => {
    if (!isFirst) {
      setSkeletonScreenLoading(true);
      setTimeout(() => {
        setSkeletonScreenLoading(false);
      }, 1200);
    }
  }, [currentTab]);

  return {
    submitForm, // 提交表单的状态和变更函数
    onChangeSubmitForm, // 修改提交表单信息的函数
    bankList, // 银行卡列表
    currentBank, // 当前选择的银行卡
    setCurrentBank, // 设置当前选择的银行卡的函数
    currentQuantity, // 当前数量
    setCurrentQuantity, // 设置当前数量的函数
    loading, // 加载状态
    availableBalance, // 可用余额
    withdraw, // 提现操作的函数
    currency, // 当前选择的法币
    changeCurrency, // 更改法币的函数
    channelList, // 通道列表
    channel, // 通道选择数据
    setChannel, // 赋值通道
    channelLoading, // 通道加载
    getChannelApi, // 请求通道接口
    virtualList, // 虚拟钱包数据
    virtualLoading, // 虚拟钱加载
    selectVirtual, // 选择虚拟钱包
    setSelectVirtual, // 选择虚拟钱包
    withdrawLoading, //提现loading
    skeletonScreenLoading,
    currentTab,
    setCurrentTab,
    tabsList,
    currentRate,
    withdrawalMethod,
    setWithdrawalMethod,
    finalAmount,
    baseCurrency,
    isRateWithdraw,
  };
}

/**
 * 定义了withdrawType类型，为useStateHooks函数的返回类型。
 */
export type withdrawType = ReturnType<typeof useStateHooks>;

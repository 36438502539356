import {BaseLoadingDisplay} from '@/components/base/baseLoadingDisplay';
import BaseModal from '@/components/base/baseModal';
import PageEnum from '@/enums/pageEnum';
import BankCard from '@/pages/mine/bank/components/BankCard';
import {OperatingEnum, TypeEnum} from '@/pages/mine/bank/useStateHooks';
import {history} from '@@/core/history';
import {Button, ScrollShadow, useDisclosure} from '@nextui-org/react';
import {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

/**
 * 银行卡选择组件
 *
 * @param {IProps} props 组件接收的属性
 * @param {any[]} bankList 银行卡列表
 * @param {ReactNode} children 子组件，点击会触发银行列表的显示
 * @param {any} setCurrentBank 设置当前选择的银行卡的函数
 * @param {boolean} loading 加载状态，控制加载动画的显示
 * @returns 渲染的JSX元素
 */
export default ({ bankList, children, setCurrentBank, loading }: IProps) => {
  // 使用Disclosure hook管理抽屉的开闭状态
  const { isOpen, onOpen, onClose } = useDisclosure();
  // 使用国际化hook获取翻译函数
  const { t } = useTranslation();

  return (
    <>
      <>
        {/*点击子组件打开抽屉*/}
        <div onClick={onOpen}>{children}</div>
        <BaseModal isOpen={isOpen} onClose={onClose} title={t('选择银行卡')}>
          <ScrollShadow hideScrollBar className="max-h-[50vh] sm:h-[40vh]">
            {/*加载银行卡列表，如果处于加载状态则显示加载动画*/}
            <BaseLoadingDisplay loading={loading} list={bankList}>
              <div className="px-4 sm:w-[500px]">
                {/*遍历银行卡列表，每个银行卡信息展示为一个可点击的卡片*/}
                {bankList?.map((bankInfo: any) => (
                  <div
                    key={bankInfo.id}
                    onClick={() => {
                      setCurrentBank(bankInfo);
                      onClose(); // 选择银行卡后关闭抽屉
                    }}
                  >
                    <BankCard bankInfo={bankInfo} type={'bank' as any} />
                  </div>
                ))}
              </div>
            </BaseLoadingDisplay>
          </ScrollShadow>
          <div className="px-4 pt-4 sm:relative w-full  pb-[30px] bg-background">
            <Button
              className="mainColorButton"
              onClick={() => {
                // 点击按钮时，跳转到添加收款方式页面
                history.push(
                  PageEnum.ADD_COLLECTION +
                  '?type=' +
                  TypeEnum.BANK +
                  '&operatingType=' +
                  OperatingEnum.ADD,
                );
              }}
            >
              {t('添加收款方式')}
            </Button>
          </div>
        </BaseModal>
      </>
    </>
  );
};

// 组件属性接口
interface IProps {
  bankList: any[];
  children: ReactNode;
  setCurrentBank: any;
  loading: boolean;
}

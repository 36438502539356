import {TeenyiconsDownSolid} from '@/assets/icons/comm/TeenyiconsDownSolid';
import {MingcuteTransferFill} from '@/assets/icons/funds/MingcuteTransferFill';
import BaseInput from '@/components/base/baseInput';
import BaseSkeleton from '@/components/base/baseSkeleton';
import NumberInput from '@/components/features/featuresNumberInput';
import FeaturesSelectCurrencyDrawer from '@/components/features/featuresSelectCurrencyDrawer';
import WithdrawBankDrawer from '@/pages/funds/withdraw/components/WithdrawBankDrawer';
import WithdrawVirtual from '@/pages/funds/withdraw/components/WithdrawVirtual';
import {
    PayoutsMethodEnum,
    withdrawType,
} from '@/pages/funds/withdraw/useStateHooks';
import {cn} from '@/utils';
import RenderUtil from '@/utils/RenderUtil';
import {useModel} from '@@/exports';
import {Button} from '@nextui-org/react';
import {useUpdateEffect} from 'ahooks';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

/***
 * 提现组件
 * */
export default ({
                    submitForm, // 提交表单数据
                    setCurrentBank, // 设置当前银行卡
                    onChangeSubmitForm, // 改变提交表单数据的回调函数
                    channel, // 汇率
                    bankList, // 银行卡列表
                    currentBank, // 当前选中的银行卡
                    currentQuantity, // 当前选中的提现金额
                    loading, // 加载状态
                    setCurrentQuantity, // 设置当前提现金额
                    withdraw, // 提现函数
                    currency, // 当前币种
                    changeCurrency, // 改变币种的回调函数
                    availableBalance,
                    channelList, // 通道数据
                    setChannel, // 通道赋值
                    setSelectVirtual, // 选择虚拟钱包
                    selectVirtual, // 选择虚拟钱包
                    virtualLoading, // 虚拟钱加载
                    virtualList, // 虚拟钱包数据
                    withdrawLoading, // 虚拟钱包数据,
                    skeletonScreenLoading,
                    currentTab,
                    currentRate,
                    withdrawalMethod,
                    setWithdrawalMethod,
                    finalAmount,
                    baseCurrency,
                    isRateWithdraw,
                }: withdrawType) => {
    const {t} = useTranslation(); // 使用翻译钩子函数
    const {joinSymbol, getMoneyLength} = useModel('exchange');

    // 快捷金额
    const shortcutAmount =
        channel?.shortcutAmount === '' || !channel?.shortcutAmount
            ? []
            : channel?.shortcutAmount?.split(',');

    //当通道发生改变
    useUpdateEffect(() => {
        changeCurrency(channelList?.[0]?.coin);
    }, [channelList]);

    return (
        <>
        {skeletonScreenLoading}
            <div className="px-4 mt-10 relative">
                <div
                    className={cn(``, {
                        'opacity-0': skeletonScreenLoading,
                    })}
                >
                    <div className="py-2">
                        {currentTab === PayoutsMethodEnum.CARD_CASH_ADVANCES && (
                            <div className="mb-3">
                                <div className="mb-1 flex justify-between">
                  <span className="text-sm text-auxiliaryTextColor">
                    {t('银行卡')}
                  </span>
                                </div>
                                {/* 银行卡选择 */}
                                <WithdrawBankDrawer
                                    bankList={bankList}
                                    setCurrentBank={setCurrentBank}
                                    loading={loading}
                                >
                                    {
                                        <div
                                            className="flex cursor-pointer justify-between items-center rounded-[3px] px-3 h-[40px] bg-backgroundAuxiliaryColor">
                                            {currentBank && (
                                                <span>{(currentBank as any)?.cardNumber}</span>
                                            )}
                                            {!currentBank && <span>{t('选择银行卡')}</span>}
                                            <TeenyiconsDownSolid
                                                className=" text-auxiliaryTextColor w-[10px] h-[10px]"/>
                                        </div>
                                    }
                                </WithdrawBankDrawer>
                            </div>
                        )}

                        {/*转账地址选择*/}
                        {currentTab ===
                            PayoutsMethodEnum.WITHDRAWAL_OF_VIRTUAL_CURRENCY && (
                                <div className="mb-2">
                                    <div className="mb-1 flex justify-between">
                  <span className="text-sm  text-auxiliaryTextColor">
                    {t('转账地址')}
                  </span>
                                    </div>
                                    {/* 选择虚拟钱包 */}
                                    <WithdrawVirtual
                                        virtualList={virtualList}
                                        setSelectVirtual={setSelectVirtual}
                                        loading={virtualLoading}
                                    >
                                        {
                                            <div
                                                className="flex  cursor-pointer justify-between items-center rounded-[3px] px-3 h-[40px] bg-backgroundAuxiliaryColor truncate w-full ">
                                                {selectVirtual && (
                                                    <div className="flex items-center w-full">
                          <span className="w-full truncate">
                            {(selectVirtual as any)?.addressUrl}
                          </span>
                                                    </div>
                                                )}
                                                {!selectVirtual && <span>{t('选择转账地址')}</span>}
                                                <TeenyiconsDownSolid
                                                    className=" text-auxiliaryTextColor w-[10px] h-[10px]"/>
                                            </div>
                                        }
                                    </WithdrawVirtual>
                                </div>
                            )}

                        {/*转账地址选择后展示的信息*/}
                        {currentTab === PayoutsMethodEnum.WITHDRAWAL_OF_VIRTUAL_CURRENCY &&
                            selectVirtual && (
                                <div className="mb-3 flex items-center text-xs text-auxiliaryTextColor">
                                    <img
                                        src={selectVirtual?.networkIcon}
                                        className="w-4 h-4 rounded-full mr-2"
                                        alt=""
                                    />
                                    <span>{selectVirtual?.networkName}</span>
                                </div>
                            )}

                        {/*通道选择*/}
                        {/*{currentTab === PayoutsMethodEnum.CARD_CASH_ADVANCES && (*/}
                        {/*  <div className="mb-3">*/}
                        {/*    <div className="mb-1 flex justify-between">*/}
                        {/*      <span className="text-sm  text-auxiliaryTextColor">*/}
                        {/*        {t('提现通道')}*/}
                        {/*      </span>*/}
                        {/*    </div>*/}
                        {/*    /!* 选择提现通道 *!/*/}
                        {/*    <WithdrawChannel*/}
                        {/*      channelList={channelList}*/}
                        {/*      currentChannel={channel}*/}
                        {/*      setChannel={(item: any) => {*/}
                        {/*        setChannel(item);*/}
                        {/*        changeCurrency(item?.coin);*/}
                        {/*      }}*/}
                        {/*      loading={skeletonScreenLoading}*/}
                        {/*    >*/}
                        {/*      <div className="flex cursor-pointer justify-between items-center rounded-[3px] px-3 h-[40px] bg-backgroundAuxiliaryColor">*/}
                        {/*        {channel && <span>{(channel as any)?.name}</span>}*/}
                        {/*        {!channel && <span>{t('选择提现通道')}</span>}*/}
                        {/*        <TeenyiconsDownSolid className=" text-auxiliaryTextColor w-[10px] h-[10px]" />*/}
                        {/*      </div>*/}
                        {/*    </WithdrawChannel>*/}
                        {/*  </div>*/}
                        {/*)}*/}

                        <div className="mb-3">
                            <div className="mb-1 flex justify-between">
                  <span className="text-sm  text-auxiliaryTextColor">
                    {t('币种')}
                  </span>
                            </div>
                            {/* 币种选择 */}
                            <FeaturesSelectCurrencyDrawer
                                currency={currency}
                                coinNameField={'alias'}
                                coinTagField={'icon'}
                                coinIdField={'id'}
                                currencyList={channelList?.map((item: any) => item?.coin)}
                                changeCurrency={(item: any) => {
                                    changeCurrency(item);
                                    setChannel(
                                        channelList?.find((i: any) => i?.coin?.id === item?.id),
                                    );
                                }}
                            >
                                <div
                                    className="flex w-full cursor-pointer justify-between items-center rounded-[3px] px-3 h-[40px] bg-backgroundAuxiliaryColor">
                                    <div className="flex items-center w-1/2 ">
                                        {!currency?.alias && <span>{t('选择币种')}</span>}
                                        {currency?.icon && currentTab !== PayoutsMethodEnum.CARD_CASH_ADVANCES && (
                                            <img
                                                src={currency?.icon}
                                                className="w-6 h-6 rounded-full mr-2"
                                                alt=""
                                            />
                                        )}
                                        {currency?.alias && <span>{currency?.alias}</span>}
                                    </div>
                                    <TeenyiconsDownSolid className="text-auxiliaryTextColor w-[10px] h-[10px]"/>
                                </div>
                            </FeaturesSelectCurrencyDrawer>
                        </div>
                        {/*{currentTab === PayoutsMethodEnum.CARD_CASH_ADVANCES && (*/}
                        {/*    <div className="mb-3">*/}
                        {/*      <div className="mb-1 flex justify-between">*/}
                        {/*      <span className="text-sm  text-auxiliaryTextColor">*/}
                        {/*        {t('币种')}*/}
                        {/*      </span>*/}
                        {/*      </div>*/}
                        {/*      <div*/}
                        {/*          className="flex cursor-pointer justify-between items-center rounded-[3px] px-3 h-[40px] bg-backgroundAuxiliaryColor">*/}
                        {/*        {channel?.coin?.alias && <span>{channel?.coin?.alias}</span>}*/}
                        {/*        /!*<TeenyiconsDownSolid className="mr-2 text-auxiliaryTextColor" />*!/*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        <div className="mb-1">
                            <div className="flex  mb-1 items-center justify-between">
                                <div className=" text-sm  text-auxiliaryTextColor">
                                    {isRateWithdraw && withdrawalMethod === 1
                                        ? t('提现金额')
                                        : t('提现数量')}
                                </div>
                                {isRateWithdraw && (
                                    <div
                                        className="text-primary text-xs flex items-center gap-1"
                                        onClick={() => {
                                            setWithdrawalMethod(withdrawalMethod === 0 ? 1 : 0);
                                        }}
                                    >
                                        <MingcuteTransferFill/>
                                        {withdrawalMethod === 0 ? t('按数量') : t('按金额')}
                                    </div>
                                )}
                            </div>

                            {/* 提现金额输入框 */}
                            <div
                                className="flex justify-between h-[40px] items-center bg-backgroundAuxiliaryColor pr-2">
                                <NumberInput
                                    className=" overflow-hidden   "
                                    value={submitForm.rechargeAmount}
                                    len={
                                        isRateWithdraw && withdrawalMethod === 1
                                            ? baseCurrency?.transactionAccuracy
                                            : currentRate?.transactionAccuracy
                                    }
                                    onChange={(value: string) => {
                                        onChangeSubmitForm(value, 'rechargeAmount');
                                    }}
                                    placeholder={t('{{price}}起', {
                                        price: channel?.minAmount ?? 0
                                    })}
                                    classNames={{
                                        base: 'rounded-[3px] overflow-hidden h-[40px]',
                                        inputWrapper:
                                            'h-full border-none rounded-[3px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
                                    }}
                                ></NumberInput>
                                {isRateWithdraw && (
                                    <div className="mr-2">
                                        {withdrawalMethod === 1 ? 'USDT' : currentRate?.name}
                                    </div>
                                )}
                                <div
                                    className="border-0 text-primary rounded-md px-3 bg-tabBackground flex-shrink-0   py-1 text-xs"
                                    onClick={() => {
                                        onChangeSubmitForm(
                                            (availableBalance as any)?.availableBalance,
                                            'rechargeAmount',
                                        );
                                    }}
                                >
                                    {t('全部')}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 flex items-center justify-between">
                            <div className=" text-xs  text-auxiliaryTextColor">
                                {isRateWithdraw && (
                                    <div>
                                        {withdrawalMethod === 0 && (
                                            <span>
                        {' '}
                                                ≈{' '}
                                                {RenderUtil.FormatAmount(
                                                    Number(submitForm.rechargeAmount) / currentRate?.rate,
                                                    baseCurrency?.transactionAccuracy,
                                                )}
                                                {' USDT'}
                      </span>
                                        )}
                                        {withdrawalMethod === 1 && (
                                            <span>
                        {' '}
                                                ≈ {finalAmount}
                                                {` ${currentRate?.name}`}
                      </span>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className=" text-xs  text-auxiliaryTextColor">
                                {t('可用')}:
                                {joinSymbol({
                                    price: RenderUtil.FormatAmount(
                                        (availableBalance as any)?.availableBalance,
                                        currentRate?.transactionAccuracy,
                                    ),
                                    currency: channel?.coinName,
                                    isShowCoinName: true,
                                })}
                            </div>
                        </div>
                        <div className="flex justify-start flex-wrap  gap-x-[14px] gap-y-[14px] mb-3">
                            {shortcutAmount?.map((item: string, index: number) => (
                                <div
                                    key={index}
                                    className={`border-1 cursor-pointer text-center py-1 px-1 text-xs  border-backgroundAuxiliaryColor rounded-md ${
                                        currentQuantity === index ? '!border-backContrastColor' : ''
                                    }`}
                                    onClick={() => {
                                        onChangeSubmitForm(item, 'rechargeAmount');
                                        setCurrentQuantity(index);
                                    }}
                                >
                                    {RenderUtil.FormatAmount(item)}
                                </div>
                            ))}
                        </div>
                        <div className="mb-1">
                            <div className="mb-1 text-sm  text-auxiliaryTextColor">
                                {t('交易密码')}
                            </div>
                            {/* 交易密码输入框 */}
                            <BaseInput
                                type="password"
                                className="bg-backgroundAuxiliaryColor overflow-hidden  mb-3 h-[40px]"
                                value={submitForm.transactionPassword as any}
                                onChange={(e) => {
                                    onChangeSubmitForm(e.target.value, 'transactionPassword');
                                }}
                                placeholder={t('请输入交易密码')}
                                classNames={{
                                    base: 'rounded-[3px] overflow-hidden h-[40px]',
                                    inputWrapper:
                                        'h-full border-none rounded-[3px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
                                }}
                            ></BaseInput>
                        </div>

                        {!!(channel as any)?.feeType && (
                            <div className="flex justify-between text-errorColor text-xs">
                <span>
                  {t('手续费')}：{/*百分比手续费*/}
                    {(channel as any)?.feeType === 2 &&
                        RenderUtil.FormatAmount((channel as any)?.fee, 2) + '%'}
                    {/*固定手续费*/}
                    {(channel as any)?.feeType === 1 &&
                        joinSymbol({
                            price: RenderUtil.FormatAmount(
                                (channel as any)?.fee,
                                currentRate?.transactionAccuracy,
                            ),
                            currency: channel?.coinName,
                            isShowCoinName: true,
                        })}
                </span>
                                <span>
                  {t('实际到账')}：{submitForm?.rechargeAmount === '' && 0}
                                    {/*百分比手续费-实际到账*/}
                                    {(channel as any)?.feeType === 2 &&
                                        submitForm?.rechargeAmount !== '' &&
                                        joinSymbol({
                                            price: RenderUtil.FormatAmount(
                                                +finalAmount - +finalAmount * (channel?.fee * 0.01) < 0
                                                    ? 0
                                                    : +finalAmount - +finalAmount * (channel?.fee * 0.01),
                                                currentRate?.transactionAccuracy,
                                            ),
                                            currency: channel?.coinName,
                                            isShowCoinName: true,
                                        })}
                                    {/*固定手续费-实际到账*/}
                                    {(channel as any)?.feeType === 1 &&
                                        submitForm?.rechargeAmount !== '' &&
                                        joinSymbol({
                                            price: RenderUtil.FormatAmount(
                                                +finalAmount - (channel as any)?.fee < 0
                                                    ? 0
                                                    : +finalAmount - (channel as any)?.fee,
                                                currentRate?.transactionAccuracy,
                                            ),
                                            currency: channel?.coinName,
                                            isShowCoinName: true,
                                        })}
                </span>
                            </div>
                        )}
                    </div>
                    {/* 提现按钮 */}
                    <Button
                        isLoading={withdrawLoading}
                        className="rounded-md cursor-pointer bg-primary text-primaryButtonTextColor mt-2 text-center w-full text-md"
                        onClick={() => {
                            if ((availableBalance as any)?.availableBalance < finalAmount) {
                                toast.error(t('当前余额不足'));
                                return;
                            }

                            if (channel?.minAmount && finalAmount < channel?.minAmount) {
                                return toast.error(t('提现金额不能低于') + channel?.minAmount);
                            }

                            if (channel?.maxAmount && finalAmount > channel?.maxAmount) {
                                return toast.error(t('提现金额不能高于') + channel?.maxAmount);
                            }

                            withdraw();
                        }}
                    >
                        {t('点击提现')}
                    </Button>
                </div>
                {/*skeletonScreenLoading*/}
                <BaseSkeleton
                    isLoaded={skeletonScreenLoading}
                    className={'absolute top-0 left-4 right-4'}
                >
                    <div className="flex flex-col justify-between  mb-2">
                        <div className="mb-2 flex justify-between bg-backgroundAuxiliaryColor w-1/3">
              <span className="text-xs font-bold text-auxiliaryTextColor">
                &nbsp;
              </span>
                        </div>
                        <div className=" bg-backgroundAuxiliaryColor h-[40px] rounded-md "></div>
                    </div>
                    <div className="mb-4 flex justify-between bg-backgroundAuxiliaryColor w-1/3">
            <span className="text-xs font-bold text-auxiliaryTextColor">
              &nbsp;
            </span>
                    </div>
                    <div className="flex flex-col justify-between  mb-3">
                        <div className="mb-2 flex justify-between bg-backgroundAuxiliaryColor  w-1/3">
              <span className="text-xs font-bold text-auxiliaryTextColor">
                &nbsp;
              </span>
                        </div>
                        <div className=" bg-backgroundAuxiliaryColor h-[40px] rounded-md "></div>
                    </div>

                    <div className="flex flex-col justify-between h-[20px] mb-3">
                        <div className=" bg-backgroundAuxiliaryColor h-full  rounded-md "></div>
                    </div>
                    <div className="flex flex-col justify-between  mb-3">
                        <div className=" bg-backgroundAuxiliaryColor h-[40px] rounded-md "></div>
                    </div>
                    <div className="flex flex-col justify-between h-[20px] mb-3">
                        <div className=" bg-backgroundAuxiliaryColor h-full  rounded-md "></div>
                    </div>
                    <div className="flex flex-col justify-between  mb-3">
                        <div className=" bg-backgroundAuxiliaryColor h-[40px] rounded-md "></div>
                    </div>
                    <div className="flex flex-col justify-between h-[20px] mb-4">
                        <div className=" bg-backgroundAuxiliaryColor h-full  rounded-md "></div>
                    </div>
                    <div className="flex flex-col justify-between h-[60px] mb-3">
                        <div className=" bg-backgroundAuxiliaryColor h-full  rounded-md "></div>
                    </div>
                </BaseSkeleton>
            </div>
        </>
    );
};
